(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:LastCancellation
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('LastCancellation', LastCancellation);

  function LastCancellation($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/matches/:id/lastCancellation', {id: '@_id'}, {
      update: {method: 'PATCH'}
    })
  }
}());
